<template>
  <div>
    <el-upload
      action="http://106.55.3.243:3000/upload/image"
      list-type="picture-card"
      :on-preview="handlePictureCardPreview"
      :on-remove="handleRemove"
      :headers="getToken"
      :data="uploadIconData"
      :on-success="successUpload"
      :limit="8"
    >
      <i class="el-icon-plus"></i>
    </el-upload>
    <el-dialog :visible.sync="dialogVisible">
      <img width="100%" :src="dialogImageUrl" alt="" />
    </el-dialog>
    <div class="el-upload__tip" slot="tip">只能上传jpg/png/gif类型和600*600的文件，且不超过2M大小,不超过8张</div>
  </div>
</template>

<script>
import { GetLogoImage } from "@/api/goods";

export default {
  name: "",
  data() {
    return {
      dialogImageUrl: "",
      dialogVisible: false,
      uploadIconData: {folder: 'prd'},
      images:[]
    };
  },
  computed: {
    getToken() {
      return {Authorization:"Bearer " + sessionStorage.getItem('jwttoken')}
    }
  },
  components: {},
  mounted() {
    // let rs = GetLogoImage();
    // console.log(rs);
  },
  methods: {
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    successUpload (response, file, fileList, $event) {
      console.log(response);
      this.images.push(response.data)
      console.log(this.images);
      this.$store.commit('getPics',this.images);
    },
  },
};
</script>

<style>
</style>