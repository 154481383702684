<template>
  <div>
    <quill-editor ref="text" v-model="content" class="myQuillEditor" :options="editorOption" />
    <div class="ql-container ql-snow quill-editor">
      <div class="ql-editor">{{content}}</div>
    </div>
  </div>
</template>

<script>
// import 'quill/dist/quill.core.css'
// import 'quill/dist/quill.snow.css'
// import 'quill/dist/quill.bubble.css'
// import { quillEditor } from "vue-quill-editor";

export default {
  name: '',
  data () {
    return {
      content: '',
    editorOption: {
      placeholder: '请输入文字'
    } 
    };
  },
  components: {
    // quillEditor
  },
  mounted () {},
  methods: {
    submit () {
    console.log(this.$refs.text.value)
  },
  nextClick() {
    console.log(this.content);
    this.$store.commit('getGoodsDesc',this.content);
  }
  }
}
</script>

<style>
/* .myQuillEditor {
  height: 300px;
  margin-bottom: 100px;
} */
.quill-editor {
  height: 250px;
  max-width: 1150px;
  margin-bottom: 40px;
}
</style>