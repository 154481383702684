<template>
  <div>
    <el-dialog
      title="用户"
      :visible.sync="dialogFormVisible"
      @close="dialogClose"
    >
      <el-form :model="user" :rules="rules" ref="user" label-width="80px">
        <el-form-item label="用户名" prop="username">
          <el-input
            v-model="user.username"
            style="width: 270px"
            placeholder="请输入用户名"
            prefix-icon="iconfont icon-suoding"
          ></el-input>
        </el-form-item>
        <el-form-item label="密码" prop="passwd">
          <el-input
            v-model="user.passwd"
            style="width: 270px"
            placeholder="请输入密码"
            show-password
            prefix-icon="iconfont icon-grade"
          ></el-input>
        </el-form-item>
        <el-form-item label="手机" prop="mobile">
          <el-input
            v-model="user.mobile"
            style="width: 270px"
            placeholder="手机"
            prefix-icon="iconfont icon-suoding"
          ></el-input>
        </el-form-item>
        <el-form-item label="邮箱" prop="email">
          <el-input
            v-model="user.email"
            style="width: 270px"
            placeholder="邮箱"
            prefix-icon="iconfont icon-suoding"
          ></el-input>
        </el-form-item>
        <el-form-item label="用户等级" prop="roleid">
          <el-radio v-model="user.roleid" :label="2">普通会员</el-radio>
          <el-radio v-model="user.roleid" :label="1">超级管理员</el-radio>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogClose">取 消</el-button>
        <el-button v-if="!isUpdate" type="primary" @click="add"
          >确 定</el-button
        >
        <el-button v-if="isUpdate" type="primary" @click="upd">修 改</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { Message } from "element-ui";
import { validatePass } from "@/utils/formrules";
import { addUsers,updUsers } from "../../../api/users";

export default {
  name: "",
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    editUser: {
      type: Object,
    },
  },
  data() {
    return {
      isUpdate: false,
      dialogFormVisible: this.isShow,
      user: {
        username: "",
        passwd: "",
        mobile: "",
        email: "",
        roleid: 2,
      },
      formLabelWidth: "120px",
      rules: {
        username: [
          { required: true, message: "请输入用户名", trigger: "blur" },
          {
            min: 3,
            max: 15,
            message: "长度在 3 到 15 个字符",
            trigger: "blur",
          },
        ],
        // password: [
        //   { required: true, message: '请输入密码', trigger: 'blur' },
        //   { min: 3, max: 5, message: '长度在 6 到 15 个字符', trigger: 'blur' }
        // ]
        passwd: [
          { required: true, validator: validatePass.chkPwd, trigger: "blur" },
        ],
      },
    };
  },
  computed: {},
  watch: {
    isShow() {
      this.dialogFormVisible = this.isShow;
    },
    editUser() {
      this.user = {...this.editUser};
      this.user.passwd = "";
      if (this.editUser.id != null) {
        this.isUpdate = true;
      }
},
  },
  components: {},
  mounted() {},
  methods: {
    dialogClose() {
      this.$refs["user"].resetFields();
      this.isUpdate = false;
      this.$emit("dialogClose", false, {});
    },
    async add() {
      this.$refs["user"].validate(async (valid) => {
        if (valid) {
          let rs = await addUsers(this.user);
          console.log(rs);
          if (rs.code == 200) {
            Message.success(rs.msg);
          } else {
            Message.error(rs.msg);
          }
          this.$parent.queryUsersList({
            username: "",
            email: "",
            pageNo: 1,
            pageSize: this.$parent.pageSize,
          });
          this.$emit("dialogClose", false, {});
        }
      });
    },
    async upd() {
      this.$refs["user"].validate(async (valid) => {
        if (valid) {
          console.log(this.user);
          let rs = await updUsers(this.user);
          console.log(rs);
          if (rs.code == 200) {
            Message.success(rs.msg);
          } else {
            Message.error(rs.msg);
          }
          this.$parent.queryUsersList({
            username: "",
            email: "",
            pageNo: 1,
            pageSize: this.$parent.pageSize,
          });
          this.isUpdate = false;
          this.$emit("dialogClose", false, {});
        }
      });
    },
  },
};
</script>

<style lang='less' scoped>
</style>