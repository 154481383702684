<template>
  <el-card class="box-card">
    <template>
      <el-alert
        title="添加商品信息"
        type="info"
        :closable="false"
        center
        show-icon
      ></el-alert>
    </template>
    <el-steps :active="active" finish-status="success" class="mySteps">
      <el-step title="基本信息"></el-step>
      <el-step title="商品属性"></el-step>
      <el-step title="商品logo"></el-step>
      <el-step title="商品图片"></el-step>
      <el-step title="完成"></el-step>
    </el-steps>
    <el-tabs v-model="activeTab" tab-position="left">
      <el-tab-pane label="基本信息" name="1">
        <basic-message ref="basicMessage"></basic-message>
      </el-tab-pane>
      <el-tab-pane
        label="商品属性"
        name="2"
        :lazy="true"
        :disabled="activeTab < 2 ? true : false"
      >
        <goods-attribute ref="attribute" v-if="activeTab == 2"></goods-attribute>
      </el-tab-pane>
      <el-tab-pane
        label="商品logo"
        name="3"
        :lazy="true"
        :disabled="activeTab < 3 ? true : false"
      >
        <goods-logo ref="logo"></goods-logo>
      </el-tab-pane>
      <el-tab-pane
        label="商品图片"
        name="4"
        :lazy="true"
        :disabled="activeTab < 4 ? true : false"
      >
        <goods-upload-img></goods-upload-img>
      </el-tab-pane>
      <el-tab-pane
        label="商品内容"
        name="5"
        :lazy="true"
        :disabled="activeTab < 5 ? true : false"
      >
        <goods-content ref="content"></goods-content>
      </el-tab-pane>
    </el-tabs>
    <el-button style="margin-top: 12px" @click="next">下一步</el-button>
  </el-card>
</template>

<script>
import { AddGoods } from "@/api/goods";
import BasicMessage from "@/components/addGoods/BasicMessage";
import GoodsAttribute from "@/components/addGoods/GoodsAttribute";
import GoodsLogo from "@/components/addGoods/GoodsLogo";
import GoodsUploadImg from "@/components/addGoods/GoodsUploadImg";
import GoodsContent from "@/components/addGoods/GoodsContent.vue";

export default {
  name: "AddGoods",
  data() {
    return {
      active: 0,
      activeTab: "1",
      isNext: false,
    };
  },
  components: {
    BasicMessage,
    GoodsAttribute,
    GoodsLogo,
    GoodsUploadImg,
    GoodsContent,
  },
  mounted() {},
  methods: {
    async next() {
      switch (this.activeTab) {
        case "1":
          this.isNext = this.$refs["basicMessage"].nextClick();
          break;
        case "2":
          this.$refs["attribute"].nextClick();
          break;
        case "5":
          this.$refs["content"].nextClick();
          break;
      }
      console.log("判断："+this.isNext);
      if (this.isNext) {
        if (this.active++ > 5) this.active = 0;
        if (this.activeTab !== "5") {
          this.activeTab = (Number(this.activeTab) + 1).toString();
        } else {
          alert("该动手了!");
          this.$store.commit("getGoodsCate");
          let rs = await AddGoods(this.$store.state.goods.goods);
          console.log(rs);
          if (rs.code == 200) {
            this.$router.push("/goodsList");
          }
        }
      }
      console.log(this.activeTab);
      console.log(this.$store.state.goods);
    },
  },
};
</script>

<style>
.text {
  font-size: 14px;
}

.item {
  padding: 18px 0;
}

.box-card {
  width: 100%;
}
.mySteps {
  margin: 15px 0;
}
.test {
  color: aqua;
}
</style>