import requestObj from "../utils/request";
import moment from "../utils/moment.js";

export const GoodsList = async (obj) => {
    let rs = await requestObj.post("/goods/listgoods",obj);
    for (const obj of rs.data.rows) {
        obj.chkStatus = obj.chkStatus == 1 ? true : false;
        obj.isLayout = obj.isLayout == 1 ? true : false;
        obj.createdAt = moment(obj.createdAt).format('YYYY-MM-DD HH:mm:ss');
    }
    return rs;
}

export const UpdateGoodsStatus = (obj) => {
    let goods = {...obj};
    goods.chkStatus = goods.chkStatus == true ? 1 : 2;
    return requestObj.post("/goods/updgoods",goods);
}

export const UpdateGoodsLayout = (obj) => {
    let goods = {...obj};
    goods.isLayout = goods.isLayout == true ? 1 : 2;
    return requestObj.post("/goods/updgoods",goods);
}

export const AddGoods = (obj) => {
    return requestObj.post("/goods/addgoods",obj);
}


