<template>
  <div>
    <div style="margin: 15px 0px 15px 5%">
      <el-input v-model="input3" placeholder="请输入内容"></el-input>
      <el-button type="primary" @click="vagueQuery" icon="el-icon-search"
        >搜索</el-button
      >
      <el-button @click="addGoodsClick" type="primary">添加</el-button>
    </div>
    <el-table
      :data="tableData"
      border
      height="600"
      style="width: 90%; margin: 0 auto"
    >
      <el-table-column prop="id" label="序号"> </el-table-column>
      <el-table-column prop="goodsSmallLogo" label="缩略图" width="180">
        <template slot-scope="scope">
          <img
            :src="imgUrl + scope.row.goodsSmallLogo"
            alt="缩略图"
            width="128"
            height="128"
          />
        </template>
      </el-table-column>
      <el-table-column prop="goodsName" label="商品名"> </el-table-column>
      <el-table-column prop="goodsPrice" label="价格"> </el-table-column>
      <el-table-column prop="goodsWeight" label="重量"> </el-table-column>
      <el-table-column prop="goodsNumber" label="数量"> </el-table-column>
      <el-table-column prop="chkStatus" label="上/下架">
        <template slot-scope="scope">
          <el-switch
            v-model="scope.row.chkStatus"
            @change="chkStatusChanged(scope.row)"
          >
          </el-switch>
        </template>
      </el-table-column>
      <el-table-column prop="isLayout" label="是否首页">
        <template slot-scope="scope">
          <el-switch
            v-model="scope.row.isLayout"
            @change="layoutChanged(scope.row)"
          >
          </el-switch>
        </template>
      </el-table-column>
      <el-table-column prop="createdAt" label="发布时间"> </el-table-column>
      <el-table-column fixed="right" label="操作" width="100">
        <template slot-scope="scope">
          <el-dropdown @command="goodsModify">
            <span class="el-dropdown-link">
              选择操作<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item :command="[scope.row,1]">基本编辑</el-dropdown-item>
              <el-dropdown-item :command="[scope.row,2]">编辑头像</el-dropdown-item>
              <el-dropdown-item :command="[scope.row,3]">编辑详情</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :page-size="pageSize"
      layout="prev, pager, next"
      :total="total"
      :current-page="pageNo"
      @current-change="prevClick"
    >
    </el-pagination>
  </div>
</template>

<script>
import { GoodsList, UpdateGoodsStatus, UpdateGoodsLayout } from "@/api/goods";
import config from "@/utils/config.js";

export default {
  name: "",
  methods: {
    async queryGoodsList(obj) {
      let rs = await GoodsList(obj);
      console.log(rs);
      if (rs.code != 200) {
        this.$message.error("未知错误");
      }
      this.pageNo = obj.pageNo;
      this.total = rs.data.count;
      this.tableData = rs.data.rows;
    },
    handleClick(row) {
      console.log(row);
    },
    async chkStatusChanged(obj) {
      let rs = await UpdateGoodsStatus(obj);
      console.log(rs);
      if (rs.code == 200) {
        this.$message.success(rs.msg);
      }
    },
    async layoutChanged(obj) {
      let rs = await UpdateGoodsLayout(obj);
      console.log(rs);
      if (rs.code == 200) {
        this.$message.success(rs.msg);
      }
    },
    async prevClick(row) {
      this.queryGoodsList({
        goodsName: "",
        pageNo: row,
        pageSize: this.pageSize,
      });
    },
    async vagueQuery() {
      console.log(this.input3);
      this.queryGoodsList({
        goodsName: this.input3,
        pageNo: 1,
        pageSize: this.pageSize,
      });
    },
    goodsModify(row) {
      console.log(row);
    },
    addGoodsClick() {
      this.$router.push('addGoods');
    }

  },
  async mounted() {
    this.queryGoodsList({
      goodsName: "",
      pageNo: 1,
      pageSize: this.pageSize,
    });
  },
  data() {
    return {
      pageSize: 2,
      total: 0,
      pageNo: 1,
      // imgUrl: "http://lqgapidemo.quechao.xyz/images/upload/prd/",
      imgUrl: config.imgHost +"/upload/prd/",
      input3: "",
      select: "",
      tableData: [],
    };
  },
};
</script>

<style>
.el-select .el-input {
  width: 130px;
}
.input-with-select .el-input-group__prepend {
  background-color: #fff;
}
.el-input {
  width: 430px;
  margin-right: 15px;
}
.el-pagination {
  text-align: center;
}

.el-dropdown-link {
  cursor: pointer;
  color: #409eff;
}
.el-icon-arrow-down {
  font-size: 12px;
}
</style>