<template>
  <div>
    <el-form
      :model="goods"
      :rules="rules"
      ref="goods"
      label-width="80px"
      label-position="top"
    >
      <el-form-item label="商品名:" prop="goodsName">
        <el-input
          v-model="goods.goodsName"
          placeholder="请输入商品名"
          prefix-icon="iconfont icon-suoding"
        ></el-input>
      </el-form-item>
      <el-form-item label="价格(元):" prop="goodsPrice">
        <el-input
          v-model="goods.goodsPrice"
          placeholder="请输入价格"
          prefix-icon="iconfont icon-suoding"
        ></el-input>
      </el-form-item>
      <el-form-item label="重量(克):" prop="goodsWeight">
        <el-input
          v-model="goods.goodsWeight"
          placeholder="请输入重量"
          prefix-icon="iconfont icon-suoding"
        ></el-input>
      </el-form-item>
      <el-form-item label="数量:" prop="goodsNumber">
        <el-input
          v-model="goods.goodsNumber"
          placeholder="请输入数量"
          prefix-icon="iconfont icon-suoding"
        ></el-input>
      </el-form-item>
      <el-form-item label="商品分类" prop="goodsCate">
        <template>
          <el-cascader ref="myCascader" v-model="goods.goodsCate" :props="props"></el-cascader>
        </template>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { getGoodsCategory } from "@/api/goodsCategory";
import { basicMesssageRules } from "@/utils/formrules";

export default {
  name: "",
  data() {
    return {
      goods: {
        goodsName: "",
        goodsPrice: 0.0,
        goodsWeight: 0,
        goodsNumber: 0,
        pics: [],
        goodsDesc: undefined,
        goodsCate: [],
        dynamicParam: [{ attrId: "", attrValue: "", attrName: "" }],
        attrs: [],
        goodsSmallLogo: undefined,
      },
      rules: {
        goodsName: [
          { required: true, message: "请输入商品名", trigger: "blur" },
          {
            min: 3,
            max: 50,
            message: "长度在 3 到 50 个字符",
            trigger: "blur",
          },
        ],
        goodsPrice: [
          { required: true, validator: basicMesssageRules.chkPrice, trigger: "blur" }
        ],
        goodsWeight: [
          { required: true, validator: basicMesssageRules.chkWeight, trigger: "blur" }
        ],
        goodsNumber: [
          { required: true, validator: basicMesssageRules.chkNumber, trigger: "blur" }
        ],
        goodsCate: [
          { required: true, message: "请选择分类", trigger: "change" },
        ],
      },
      props: {
        lazy: true,
        async lazyLoad(node, resolve) {
          // console.log(node);
          const { level,value } = node;
          let rs = await getGoodsCategory({pid:value ? value : 0});
          // console.log(rs);
          let nodes = [];
          for (const category of rs.data) {
            nodes.push({
            value: category.id,
            label: category.cateName,
            leaf: level >= 2
          });
          }
          resolve(nodes);

        },
      },
    };
  },
  components: {},
  mounted() {},
  methods: {
    nextClick() {
      // console.log(this.goods.goodsCate);
      let flag = false;
      this.$refs['goods'].validate((valid) => {
          if (valid) {
            console.log('submit!');
            this.$store.commit('getGoods',this.goods);
            this.$store.dispatch('getGoodsAttrActions');
            flag = true;
            return flag;
          } else {
            console.log('error submit!!');
            flag = false;
            return flag;
          }
        });
        return flag;
    }
  },
};
</script>

<style>
.el-cascader-panel {
  height: 220px;
}
.el-scrollbar__wrap{
  overflow-x: hidden;
}
</style>