<template>
  <div>
    <el-form
      :model="attr"
      :rules="rules"
      ref="goodsAttr"
      label-width="80px"
      label-position="top"
    >
      <el-form-item label="重量" prop="goodsWeights">
        <el-checkbox-group v-model="attr.goodsWeights">
          <el-checkbox
            v-for="item in goodsAttr[0].attrValue"
            :label="item"
            :key="item"
            border
          ></el-checkbox>
        </el-checkbox-group>
      </el-form-item>
      <el-form-item label="颜色" prop="goodsColor">
        <el-checkbox-group v-model="attr.goodsColor">
          <el-checkbox
            v-for="item in goodsAttr[1].attrValue"
            :label="item"
            :key="item"
            border
          ></el-checkbox>
        </el-checkbox-group>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { getGoodsAttr } from "@/api/goodsCategory";

export default {
  name: "",
  data() {
    return {
      attr: {
        goodsWeights: [],
        goodsColor: [],
      },
      goodsAttr: [
        { attrId: "", attrValue: [], attrName: "" },
        { attrId: "", attrValue: [], attrName: "" },
      ],
      // goodsAttr: { attrId: "", attrValue: "", attrName: "" },
      rules: {},
    };
  },
  components: {},
  async mounted() {
    let rs = await getGoodsAttr({"catId":this.$store.state.goods.goods.goodsCate[2],"attrSel":2});
    for (let i = 0; i < rs.data.length; i++) {
      this.$set(this.goodsAttr[i],'attrValue',rs.data[i].attrVals.split("_"));
      this.$set(this.goodsAttr[i],'attrId',rs.data[i].id);
      this.$set(this.goodsAttr[i],'attrName',rs.data[i].attrName);
    }
    console.log(rs);
  },
  methods: {
    nextClick() {
      let temp1 = 0;
      let arr1 = [];
      for (const key in this.attr) {
        let obj = {...this.goodsAttr[temp1]};
        obj.attrValue = this.attr[key].join("_");
        arr1.push(obj);
        temp1++;
      }
      this.$store.commit('getDynamicParam',arr1);
    },
  },
};
</script>

<style>
</style>