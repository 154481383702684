<template>
  <div>
    <el-upload
      action="http://106.55.3.243:3000/upload/image"
      list-type="picture-card"
      :on-preview="handlePictureCardPreview"
      :on-remove="handleRemove"
      :headers="getToken"
      :data="uploadIconData"
      :before-upload="beforeAvatarUpload"
      :on-success="successUpload"
      :limit="1"
      
    >
      <i class="el-icon-plus"></i>
    </el-upload>
    <el-dialog :visible.sync="dialogVisible">
      <img width="100%" :src="dialogImageUrl" alt="" />
    </el-dialog>
    <div class="el-upload__tip" slot="tip">只能上传jpg/png/gif类型和600*600的文件，且不超过2M</div>
  </div>
</template>

<script>
import { GetLogoImage } from "@/api/goods";

export default {
  name: "",
  data() {
    return {
      dialogImageUrl: "",
      // folder: "prd",
      dialogVisible: false,
      uploadIconData: {folder: 'prd'}
    };
  },
  computed: {
    getToken() {
      return {Authorization:"Bearer " + sessionStorage.getItem('jwttoken')}
    }
  },
  components: {},
  mounted() {},
  methods: {
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    successUpload (response, file, fileList, $event) {
      console.log(response);
      this.$store.commit('getGoodsSmallLogo',response.data);
    },
    beforeAvatarUpload(file) {
        const isLt2M = file.size / 2048 / 2048 < 2;
        if (!isLt2M) {
          this.$message.error('上传头像图片大小不能超过 4MB!');
        }
        return isLt2M;
      }
  },
};
</script>

<style>
</style>