<template>
  <div>
    <div style="margin: 15px 0px 15px 5%">
      <el-input v-model="input3" placeholder="请输入内容"></el-input>
      <el-button type="primary" @click="vagueQuery" icon="el-icon-search"
        >搜索</el-button
      >
      <el-button type="primary" @click="addClick">添加</el-button>
    </div>
    <el-table
      :data="tableData"
      border
      height="600"
      style="width: 90%; margin: 0 auto"
    >
      <el-table-column prop="id" label="序号"> </el-table-column>
      <el-table-column prop="username" label="用户名"> </el-table-column>
      <el-table-column prop="email" label="邮箱"> </el-table-column>
      <el-table-column prop="mobile" label="手机"> </el-table-column>
      <el-table-column prop="chkStatus" label="状态">
        <template slot-scope="scope">
          <el-switch
            v-model="scope.row.chkStatus"
            active-color="#13ce66"
            inactive-color="#ff4949"
            @change="statusChanged(scope.row)"
          >
          </el-switch>
        </template>
      </el-table-column>
      <el-table-column prop="rname" label="角色名"> </el-table-column>
      <el-table-column fixed="right" label="操作" width="100">
        <template slot-scope="scope">
          <el-button type="text" size="small" @click="editClick(scope.row)"
            >编辑</el-button
          >
          <el-button @click="handleClick(scope.row.id)" type="text" size="small"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :page-size="pageSize"
      layout="prev, pager, next"
      :total="total"
      @current-change="prevClick"
    >
    </el-pagination>
    <add-admin :isShow="flag" :editUser="users" @dialogClose="dialogClose"></add-admin>
  </div>
</template>

<script>
import { userList,deleteUsers,updUsers } from "../../../api/users";
import AddAdmin from "./addAdmin";

export default {
  name: "",
  methods: {
    async queryUsersList(obj) {
      let rs = await userList(obj);
      console.log(rs);
      if (rs.code != 200) {
        this.$message.error("未知错误");
      }
      this.$message.success(rs.msg);
      this.total = rs.data.count;
      this.tableData = rs.data.rows;
    },
    async handleClick(id) {
      let rs = await deleteUsers({id:id});
      console.log(rs);
      this.queryUsersList({
          username: "",
          email: "",
          pageNo: 1,
          pageSize: this.pageSize,
        });
    },
    //修改用户状态
    async statusChanged(obj) {
      let rs = await updUsers(obj);
      if (rs.code == 200) {
        this.$message.success(rs.msg);
      }
    },
    //分页改变
    async prevClick(row) {
      this.queryUsersList({
        username: "",
        email: "",
        pageNo: row,
        pageSize: this.pageSize,
      });
    },
    //模糊查询
    async vagueQuery() {
      console.log(this.input3);
      this.queryUsersList({
        username: this.input3,
        email: "",
        pageNo: 1,
        pageSize: this.pageSize,
      })
    },
    //添加用户
    addClick() {
      this.flag = true;
    },
    //编辑用户
    editClick(obj) {
      console.log(obj);
      this.users = {...obj};
      this.flag = true;
    },
    //关闭弹窗
    dialogClose(value,obj) {
      this.flag = value;
      // this.users = obj;
    }
    
  },
  mounted() {
    this.queryUsersList({
        username: "",
        email: "",
        pageNo: 1,
        pageSize: this.pageSize,
      });
  },
  data() {
    return {
      //弹窗是否打开
      flag: false,
      pageSize: 5,
      total: 0,
      input3: "",
      select: "",
      users: {},
      tableData: [],
    };
  },
  components: {
    AddAdmin,
  },
};
</script>

<style>
.el-select .el-input {
  width: 130px;
}
.input-with-select .el-input-group__prepend {
  background-color: #fff;
}
.el-input {
  width: 430px;
  margin-right: 15px;
}
.el-pagination {
  text-align: center;
}
</style>